import React, {useEffect} from "react";
import {Typography} from "@mui/material";
import {PageProps} from "./Content";
import * as TITLES from "../constants/titles";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles(() => ({
    legalNoticeContainer: {
        width: "100%",
        maxWidth: "1280px",
        backgroundColor: "#ffffff",
        borderTopRightRadius: "10px",
        borderTopLeftRadius: "10px",
    },
    contentBlock: {
        marginBottom: "20px"
    }
}));

export default function LegalNotice(props: PageProps) {

    const classes = useStyles();

    useEffect(() => {
        props.onChangeContentTitle(TITLES.LEGAL_NOTICE_TITLE);
    }, [props]);

    return (
        <div className={classes.legalNoticeContainer}>
            <div style={{margin: "auto", padding: "40px", maxWidth: "900px"}}>
                <Typography variant="body1"
                            component="div"
                            className={classes.contentBlock}>
                    Angaben gemäß § 5 TMG
                </Typography>
                <Typography variant="body1"
                            component="div"
                            className={classes.contentBlock}>
                    <strong>Tim de Buhr</strong><br/>
                    Berliner Straße 21<br/>
                    26655 Westerstede
                </Typography>
                <Typography variant="body1"
                            component="div"
                            className={classes.contentBlock}>
                    <strong>Vertreten durch</strong><br/>
                    Tim de Buhr
                </Typography>
                <Typography variant="body1"
                            component="div"
                            className={classes.contentBlock}>
                    <strong>Kontakt:</strong><br/>
                    Telefon: +49 4488 5204130<br/>
                    Email: info@tdbit.de
                </Typography>
                <Typography variant="body1"
                            component="div"
                            className={classes.contentBlock}>
                    <strong>Umsatzsteuer-ID:</strong><br/>
                    Umsatzsteuer-Identifikationsnummer gemäß § 27a Umsatzsteuergesetz<br/>
                    DE 344195538
                </Typography>
                <Typography variant="body1"
                            component="div"
                            className={classes.contentBlock}>
                    <strong>Verantwortlich für den Inhalt nach $ 55 Abs. 2 RStV:</strong><br/>
                    Tim de Buhr<br/>
                    Berliner Straße 21<br/>
                    26655 Westerstede
                </Typography>
                <Typography variant="body1"
                            component="div"
                            className={classes.contentBlock}>
                    <strong>Haftungsausschluss:</strong>
                </Typography>
                <Typography variant="body1"
                            component="div"
                            className={classes.contentBlock}>
                    <strong>Haftung für Inhalte:</strong><br/>
                    Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit,
                    Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen.
                    Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten
                    nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als
                    Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde
                    Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige
                    Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
                    Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine
                    diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
                    Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen
                    werden wir diese Inhalte umgehend entfernen.
                </Typography>
                <Typography variant="body1"
                            component="div"
                            className={classes.contentBlock}>
                    <strong>Haftung für Links:</strong><br/>
                    Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir
                    keinen Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine
                    Gewähr übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige
                    Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum
                    Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
                    waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle
                    der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung
                    nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links
                    umgehend entfernen.
                </Typography>
                <Typography variant="body1"
                            component="div"
                            className={classes.contentBlock}>
                    <strong>Urheberrecht:</strong><br/>
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen
                    dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art
                    der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen
                    Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
                    sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte
                    auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter
                    beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
                    trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
                    entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige
                    Inhalte umgehend entfernen.
                </Typography>
                <Typography variant="body1"
                            component="div"
                            className={classes.contentBlock}>
                    <strong>Datenschutz:</strong><br/>
                    Die Nutzung unserer Webseite ist in der Regel ohne Angabe personenbezogener Daten
                    möglich. Soweit auf unseren Seiten personenbezogene Daten (beispielsweise Name,
                    Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets
                    auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht
                    an Dritte weitergegeben.<br/><br/>
                    Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation
                    per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem
                    Zugriff durch Dritte ist nicht möglich.<br/><br/>
                    Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten durch
                    Dritte zur Übersendung von nicht ausdrücklich angeforderter Werbung und
                    Informationsmaterialien wird hiermit ausdrücklich widersprochen. Die Betreiber der
                    Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten
                    Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
                </Typography>
                <Typography variant="body1"
                            component="div"
                            className={classes.contentBlock}>
                    Impressum vom&nbsp;
                    <a rel="nofollow noreferrer" href="https://www.impressum-generator.de">
                        Impressum Generator
                    </a>
                    &nbsp;der&nbsp;
                    <a rel="nofollow noreferrer" href="https://www.kanzlei-hasselbach.de/">
                        Kanzlei Hasselbach, Rechtsanwälte für Arbeitsrecht und Familienrecht
                    </a>
                </Typography>
                <Typography variant="body1"
                            component="div"
                            className={classes.contentBlock}>
                    <br/>
                </Typography>
                <Typography variant="body2"
                            component="div">
                    Quellenangaben für die verwendeten Bilder und Grafiken:<br/>
                    https://www.pexels.com/de-de/foto/apple-apfel-laptop-macbook-pro-216373/<br/>
                    https://www.pexels.com/de-de/foto/arbeit-bildschirm-code-computer-574073/<br/>
                    https://www.pexels.com/de-de/foto/usb-technologie-computer-mikrochip-57007/<br/>
                    https://www.flaticon.com/de/kostenloses-icon/xing-logo_39554<br/>
                    https://www.flaticon.com/de/kostenloses-icon/twitter_733635
                </Typography>
            </div>
        </div>
    );
}
