import React, {useEffect} from "react";
import {Theme, Typography} from "@mui/material";
import {PageProps} from "./Content";
import * as TITLES from "../constants/titles";
import {makeStyles} from "@mui/styles";

const useStyles = makeStyles((theme: Theme) => ({
    dataProtectionContainer: {
        width: "100%",
        maxWidth: "1280px",
        backgroundColor: "#ffffff",
        borderTopRightRadius: "10px",
        borderTopLeftRadius: "10px",
    },
}));

export default function DataProtection(props: PageProps) {

    const classes = useStyles();

    useEffect(() => {
        props.onChangeContentTitle(TITLES.DATA_PROTECTION_TITLE);
    }, [props]);

    return (
        <div className={classes.dataProtectionContainer}>
            <div style={{margin: "auto", padding: "40px", maxWidth: "900px"}}>
                <Typography variant="body2" component="div" className={"contentTypography"}>
                    Personenbezogene Daten (nachfolgend zumeist nur „Daten“ genannt) werden von uns nur im Rahmen der
                    Erforderlichkeit sowie zum Zwecke der Bereitstellung eines funktionsfähigen und nutzerfreundlichen
                    Internetauftritts, inklusive seiner Inhalte und der dort angebotenen Leistungen, verarbeitet.
                </Typography>
                <Typography variant="body2" component="div" className={"contentTypography"}>
                    Gemäß Art. 4 Ziffer 1. der Verordnung (EU) 2016/679, also der Datenschutz-Grundverordnung
                    (nachfolgend nur „DSGVO“ genannt), gilt als „Verarbeitung“ jeder mit oder ohne Hilfe automatisierter
                    Verfahren ausgeführter Vorgang oder jede solche Vorgangsreihe im Zusammenhang mit personenbezogenen
                    Daten, wie das Erheben, das Erfassen, die Organisation, das Ordnen, die Speicherung, die Anpassung
                    oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung durch Übermittlung,
                    Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die
                    Einschränkung, das Löschen oder die Vernichtung.
                </Typography>
                <Typography variant="body2" component="div" className={"contentTypography"}>
                    Mit der nachfolgenden Datenschutzerklärung informieren wir Sie insbesondere über Art, Umfang, Zweck,
                    Dauer und Rechtsgrundlage der Verarbeitung personenbezogener Daten, soweit wir entweder allein oder
                    gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung entscheiden. Zudem informieren wir
                    Sie nachfolgend über die von uns zu Optimierungszwecken sowie zur Steigerung der Nutzungsqualität
                    eingesetzten Fremdkomponenten, soweit hierdurch Dritte Daten in wiederum eigener Verantwortung
                    verarbeiten.
                </Typography>
                <Typography variant="body2" component="div" className={"contentTypography"}>
                    Unsere Datenschutzerklärung ist wie folgt gegliedert:
                </Typography>
                <Typography variant="body2" component="div" className={"contentTypography"}>
                    I. Informationen über uns als Verantwortliche<br/>
                    II. Rechte der Nutzer und Betroffenen<br/>
                    III. Informationen zur Datenverarbeitung
                </Typography>
                <Typography variant="body2" component="div" className={"contentTypography"}>
                    <strong>I. Informationen über uns als Verantwortliche</strong>
                </Typography>
                <Typography variant="body2" component="div" className={"contentTypography"}>
                    Verantwortlicher Anbieter dieses Internetauftritts im datenschutzrechtlichen Sinne ist:
                </Typography>
                <Typography variant="body2" component="div" className={"contentTypography"}>
                    Tim de Buhr - TdB-IT<br/>
                    Berliner Straße 21<br/>
                    26655 Westerstede<br/>
                    Deutschland
                </Typography>
                <Typography variant="body2" component="div" className={"contentTypography"}>
                    Telefon: +49 4488 5204130<br/>
                    Telefax: +49 4488 5204129<br/>
                    Email: info@tdbit.de
                </Typography>
                <Typography variant="body2" component="div" className={"contentTypography"}>
                    Datenschutzbeauftragte/r beim Anbieter ist:
                </Typography>
                <Typography variant="body2" component="div" className={"contentTypography"}>
                    Tim de Buhr&nbsp;
                </Typography>
                <Typography variant="body2" component="div" className={"contentTypography"}>
                    <strong>II. Rechte der Nutzer und Betroffenen</strong>
                </Typography>
                <Typography variant="body2" component="div" className={"contentTypography"}>
                    Mit Blick auf die nachfolgend noch näher beschriebene Datenverarbeitung haben die Nutzer und
                    Betroffenen das Recht
                </Typography>
                <Typography variant="body2" component="div" className={"contentTypography"}>
                    <ul>
                        <li>
                            auf Bestätigung, ob sie betreffende Daten verarbeitet werden, auf Auskunft über die
                            verarbeiteten Daten, auf weitere Informationen über die Datenverarbeitung sowie auf Kopien
                            der Daten (vgl. auch Art. 15 DSGVO);
                        </li>
                        <li>
                            auf Berichtigung oder Vervollständigung unrichtiger bzw. unvollständiger Daten (vgl. auch
                            Art. 16 DSGVO);
                        </li>
                        <li>
                            auf unverzügliche Löschung der sie betreffenden Daten (vgl. auch Art. 17 DSGVO), oder,
                            alternativ, soweit eine weitere Verarbeitung gemäß Art. 17 Abs. 3 DSGVO erforderlich ist,
                            auf Einschränkung der Verarbeitung nach Maßgabe von Art. 18 DSGVO;
                        </li>
                        <li>
                            auf Erhalt der sie betreffenden und von ihnen bereitgestellten Daten und auf Übermittlung
                            dieser Daten an andere Anbieter/Verantwortliche (vgl. auch Art. 20 DSGVO);
                        </li>
                        <li>
                            auf Beschwerde gegenüber der Aufsichtsbehörde, sofern sie der Ansicht sind, dass die sie
                            betreffenden Daten durch den Anbieter unter Verstoß gegen datenschutzrechtliche Bestimmungen
                            verarbeitet werden (vgl. auch Art. 77 DSGVO).
                        </li>
                    </ul>
                </Typography>

                <Typography variant="body2" component="div" className={"contentTypography"}>
                    Darüber hinaus ist der Anbieter dazu verpflichtet, alle Empfänger, denen gegenüber Daten durch den
                    Anbieter offengelegt worden sind, über jedwede Berichtigung oder Löschung von Daten oder die
                    Einschränkung der Verarbeitung, die aufgrund der Artikel 16, 17 Abs. 1, 18 DSGVO erfolgt, zu
                    unterrichten. Diese Verpflichtung besteht jedoch nicht, soweit diese Mitteilung unmöglich oder mit
                    einem unverhältnismäßigen Aufwand verbunden ist. Unbeschadet dessen hat der Nutzer ein Recht auf
                    Auskunft über diese Empfänger.
                </Typography>
                <Typography variant="body2" component="div" className={"contentTypography"}>
                    <strong>Ebenfalls haben die Nutzer und Betroffenen nach Art. 21 DSGVO das Recht auf Widerspruch
                        gegen die künftige Verarbeitung der sie betreffenden Daten, sofern die Daten durch den Anbieter
                        nach Maßgabe von Art. 6 Abs. 1 lit. f) DSGVO verarbeitet werden. Insbesondere ist ein
                        Widerspruch gegen die Datenverarbeitung zum Zwecke der Direktwerbung statthaft.</strong>
                </Typography>
                <Typography variant="body2" component="div" className={"contentTypography"}>
                    <strong>III. Informationen zur Datenverarbeitung</strong>
                </Typography>
                <Typography variant="body2" component="div" className={"contentTypography"}>
                    Ihre bei Nutzung unseres Internetauftritts verarbeiteten Daten werden gelöscht oder gesperrt, sobald
                    der Zweck der Speicherung entfällt, der Löschung der Daten keine gesetzlichen Aufbewahrungspflichten
                    entgegenstehen und nachfolgend keine anderslautenden Angaben zu einzelnen Verarbeitungsverfahren
                    gemacht werden.
                </Typography>
                <Typography variant="body2" component="div" className={"contentTypography"}>
                    <strong>Serverdaten</strong>
                </Typography>
                <Typography variant="body2" component="div" className={"contentTypography"}>
                    Aus technischen Gründen, insbesondere zur Gewährleistung eines sicheren und stabilen
                    Internetauftritts, werden Daten durch Ihren Internet-Browser an uns bzw. an unseren
                    Webspace-Provider übermittelt. Mit diesen sog. Server-Logfiles werden u.a. Typ und Version Ihres
                    Internetbrowsers, das Betriebssystem, die Website, von der aus Sie auf unseren Internetauftritt
                    gewechselt haben (Referrer URL), die Website(s) unseres Internetauftritts, die Sie besuchen, Datum
                    und Uhrzeit des jeweiligen Zugriffs sowie die IP-Adresse des Internetanschlusses, von dem aus die
                    Nutzung unseres Internetauftritts erfolgt, erhoben.
                </Typography>
                <Typography variant="body2" component="div" className={"contentTypography"}>
                    Diese so erhobenen Daten werden vorrübergehend gespeichert, dies jedoch nicht gemeinsam mit anderen
                    Daten von Ihnen.
                </Typography>
                <Typography variant="body2" component="div" className={"contentTypography"}>
                    Diese Speicherung erfolgt auf der Rechtsgrundlage von Art. 6 Abs. 1 lit. f) DSGVO. Unser
                    berechtigtes Interesse liegt in der Verbesserung, Stabilität, Funktionalität und Sicherheit unseres
                    Internetauftritts.
                </Typography>
                <Typography variant="body2" component="div" className={"contentTypography"}>
                    Die Daten werden spätestens nach sieben Tage wieder gelöscht, soweit keine weitere Aufbewahrung zu
                    Beweiszwecken erforderlich ist. Andernfalls sind die Daten bis zur endgültigen Klärung eines
                    Vorfalls ganz oder teilweise von der Löschung ausgenommen.<br/>
                </Typography>
                <Typography variant="body2" component="div" className={"contentTypography"}>
                    <a target="_blank"
                       rel="noopener noreferrer"
                       href="https://www.ratgeberrecht.eu/leistungen/muster-datenschutzerklaerung.html">
                        Muster-Datenschutzerklärung
                    </a>
                    &nbsp;der&nbsp;
                    <a target="_blank"
                       rel="noopener noreferrer"
                       href="https://www.ratgeberrecht.eu/datenschutz/datenschutzerklaerung-generator-dsgvo.html">
                        Anwaltskanzlei Weiß &amp; Partner</a>
                </Typography>
                <Typography variant="body2" component="div" className={"contentTypography"}/>
            </div>
        </div>
    );
}
