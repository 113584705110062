import {
    Button,
    Divider,
    Drawer,
    Icon,
    List,
    ListItemButton,
    useMediaQuery,
    useTheme,
    Theme,
} from "@mui/material";
import * as ROUTES from "../constants/routes";
import * as TITLES from "../constants/titles";
import React, {useState} from "react";
import {useNavigate} from "react-router";
import {makeStyles} from "@mui/styles";
import "./Header.css";

const useStyles = makeStyles((theme: Theme) => ({
    header: {
        width: "100%",
        height: "90px",
        zIndex: 10,
        position: "fixed",
        backgroundColor: "#ffffff",
        boxShadow: "0 1px 10px -6px rgba(0,0,0,.42),0 1px 10px 0 rgba(0,0,0,.12),0 4px 5px -2px rgba(0,0,0,.1)",
    },
    headerContainer: {
        backgroundImage: "url('./logo_new.png')",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "0 0",
        backgroundSize: "140px",
        maxWidth: "1280px",
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "row",
        margin: "0 auto",
        "& .MuiButton-root": {
            marginTop: "auto",
            marginBottom: "auto",
            marginRight: "10px",
        },
        "& .MuiButton-root.last": {
            marginRight: "0",
        },
        "& .MuiButton-text": {
            color: "#000000",
            fontSize: "18px"
        }
    },
    headerMenuContainer: {
        display: "flex",
        alignItems: "center",
        height: "100%",
        margin: "auto 0 auto auto",
        [theme.breakpoints.down(900)]: {
            display: "none"
        }
    },
    headerDrawerMenuContainer: {
        display: "none",
        alignItems: "center",
        height: "100%",
        margin: "auto 0 auto auto",
        [theme.breakpoints.down(900)]: {
            display: "flex",
        }
    },
    drawerMenuLink: {
        height: "100%",
        borderRadius: "0",
        "&:hover": {
        }
    },
    drawerHeader: {
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start"
    },
    drawerContentContainer: {
        overflow: "auto",
        height: "calc(100% - 50px)"
    },
    drawerMenuCloseButton: {
        borderRadius: "25px",
        padding: "5px",
        minWidth: "0",
        margin: "auto auto auto 16px",
        backgroundColor: "rgba(0, 0, 0, 0.75)",
        color: "#ffffff",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.5)"
        }
    },
    headerLink: {
        textTransform: "uppercase",
        color: "rgb(105,105,105) !important"
    }
}));

export default function Header() {

    const [open, setOpen] = useState(false);

    const classes = useStyles();
    const navigate = useNavigate();
    const theme = useTheme();
    const matchesMobile = useMediaQuery(theme.breakpoints.down(900));

    return (
        <div id="header" className={classes.header}>
            <div id="headerContainer" className={classes.headerContainer}>
                <nav className={!matchesMobile ? classes.headerMenuContainer : classes.headerDrawerMenuContainer}>
                    {!matchesMobile && (<>
                        <Button className={classes.headerLink}
                                variant={"text"}
                                color={"primary"}
                                onClick={() => {navigate(ROUTES.HOME)}}>HOME</Button>
                        <Button className={classes.headerLink}
                                variant={"text"}
                                color={"primary"}
                                onClick={() => {navigate(ROUTES.DEVELOPMENT)}}>{TITLES.DEVELOPMENT_TITLE}</Button>
                        <Button className={`${classes.headerLink} last`}
                                variant={"text"}
                                color={"primary"}
                                onClick={() => {navigate(ROUTES.LECTURES)}}>{TITLES.LECTURES_TITLE}</Button>
                    </>)}
                    {matchesMobile && (<>
                        <Button className={`${classes.drawerMenuLink} last`}
                                onClick={() => setOpen(true)}><Icon>menu</Icon></Button>
                        <Drawer variant="temporary"
                                style={{flexShrink: 0}}
                                anchor={"right"}
                                open={open}
                                onClose={() => setOpen(false)}>
                            <div className={classes.drawerHeader}>
                                <Button className={classes.drawerMenuCloseButton}
                                        onClick={() => setOpen(false)}>
                                    <Icon>navigate_next</Icon></Button>
                            </div>
                            <Divider/>
                            <div className={classes.drawerContentContainer}
                                 onClick={() => setOpen(false)}
                                 onKeyDown={() => setOpen(false)}>
                                <List>
                                    <ListItemButton onClick={() => {navigate(ROUTES.HOME)}}>
                                        <div className={classes.headerLink}>HOME</div>
                                    </ListItemButton>
                                    <ListItemButton onClick={() => {navigate(ROUTES.DEVELOPMENT)}}>
                                        <div className={classes.headerLink}>{TITLES.DEVELOPMENT_TITLE}</div>
                                    </ListItemButton>
                                    <ListItemButton onClick={() => { navigate(ROUTES.LECTURES)}}>
                                        <div className={classes.headerLink}>{TITLES.LECTURES_TITLE}</div>
                                    </ListItemButton>
                                </List>
                            </div>
                        </Drawer>
                    </>)}
                </nav>
            </div>
        </div>
    );
}

