import React from 'react';
import { createRoot } from 'react-dom/client';
import './google-icons.css';
import './google-fonts.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {StylesProvider} from "@mui/styles";
import TdbTheme from "./theme/TdbTheme";
import {ThemeProvider} from "@mui/material/styles";

const imageStyle = {
    backgroundImage: `url(/static/images/individualSoftwareDevelopment.jpg)`,
    backgroundColor: "rgba(255,255,255,0.3)",
    backgroundPosition: "center",
    backgroundRepeat: "repeat-y",
    backgroundSize: "cover",
    backgroundBlendMode: "exclusion",
    backgroundAttachment: "fixed"
}

const rootConst: HTMLElement = document.getElementById("root")!;

createRoot(rootConst).render(
    <StylesProvider injectFirst>
        <ThemeProvider theme={TdbTheme}>
            <div id={"appContainer"} style={imageStyle}>
                <App/>
            </div>
        </ThemeProvider>
    </StylesProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
