import React from "react";
import {Button, Theme} from "@mui/material";
import {useNavigate} from "react-router";
import * as ROUTES from "../constants/routes";
import {makeStyles} from "@mui/styles";
import "./Footer.css"
import * as TITLES from "../constants/titles";

const useStyles = makeStyles((theme: Theme) => ({
    footer: {
        backgroundColor: "rgb(64, 64, 64)",
        color: "rgb(255,255,255)",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        maxWidth: "1280px",
        height: "90px",
        width: "100%",
        margin: "0 auto",
        position: "relative",
        [theme.breakpoints.down(900)]: {
            flexDirection: "column",
            padding: "30px",
            width: "calc(100% - 60px)",
            height: "150px"
        }
    },
    copyright: {
        color: "rgba(255,255,255,0.5)",
        minWidth: "140px",
        textTransform: "uppercase",
        margin: "35px 20px",
        fontSize: "14px",
        lineHeight: "20px",
        position: "absolute",
        bottom: "0",
        [theme.breakpoints.down(900)]: {
            padding: "6px 8px",
            left: "10px",
            marginBottom: "30px"
        }
    },
    footerLinkContainer: {
        margin: "auto 0 auto auto",
        "& .MuiButton-root.last": {
            marginRight: "0",
        },
        [theme.breakpoints.down(900)]: {
            margin: "0 auto 60px 0",
            display: "flex",
            flexDirection: "column"
        }
    },
    footerLink: {
        margin: "auto 10px auto 0",
        color: "#ffffff !important",
        fontSize: "14px !important",
        lineHeight: "20px !important",
        [theme.breakpoints.down(900)]: {
            justifyContent: "start !important",
        }
    }
}));

export default function Footer() {

    const classes = useStyles();
    const navigate = useNavigate();

    return (
        <div id="footer" className={classes.footer}>
            <div className={classes.footerLinkContainer}>
                <Button className={classes.footerLink}
                        variant={"text"}
                        color={"primary"}
                        onClick={() => {navigate(ROUTES.LEGAL_NOTICE)}}>{TITLES.LEGAL_NOTICE_TITLE}</Button>
                <Button className={classes.footerLink}
                        variant={"text"}
                        color={"primary"}
                        onClick={() => {navigate(ROUTES.DATA_PROTECTION)}}>{TITLES.DATA_PROTECTION_TITLE}</Button>
            </div>
            <div className={classes.copyright}>Copyright 2025</div>
        </div>
    );
}
