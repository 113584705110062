import React, {CSSProperties, useEffect} from "react";
import {Button, Checkbox, Icon, TextField, Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {PageProps} from "./Content";
import * as TITLES from "../constants/titles";
import {Theme} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
    contentWithBackground: {
        backgroundColor: "rgb(255, 255, 255)",
        display: "flex",
        flexDirection: "column"
    },
    contentTeaser: {
        margin: "80px auto calc(80px - 15px) auto",
    },
    contentHeader: {
        fontSize: "40px",
        fontWeight: 700,
        textAlign: "center",
        margin: "0 30px 30px 30px"
    },
    contentBody: {
        margin: "0 30px 15px 30px",
        maxWidth: "800px",
        textAlign: "center",
    },
    contentDetails: {
        padding: "0 20px",
        display: "flex",
        flexDirection: "row",
        marginBottom: "80px",
        [theme.breakpoints.down(900)]: {
            flexDirection: "column"
        }
    },
    contentDetail: {
        width: "33%",
        textAlign: "center",
        padding: "0 10px",
        [theme.breakpoints.down(900)]: {
            width: "100%"
        }
    },
    contentDetailIcon: {
        color: "rgb(63,112,179)"
    },
    contentAboutMe: {
        backgroundColor: "rgba(0, 0, 0, 0.45)",
        color: "#ffffff",
        height: "500px",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyItems: "center",
        [theme.breakpoints.down(900)]: {
            flexDirection: "column"
        }
    },
    contentAboutMeContact: {
        display: "flex",
        flexDirection: "column",
        margin: "auto auto auto 30px",
        width: "33%",
        [theme.breakpoints.down(900)]: {
            width: "calc(100% - 60px)",
            margin: "auto 30px auto 30px",
            textAlign: "center"
        }
    },
    contentAboutMeAvatar: {
        display: "flex",
        flexDirection: "column",
        width: "33%",
        margin: "auto",
        [theme.breakpoints.down(900)]: {
            width: "100%"
        }
    },
    contentAboutMeDetails: {
        display: "flex",
        flexDirection: "column",
        width: "33%",
        margin: "auto 30px auto auto",
        [theme.breakpoints.down(900)]: {
            marginRight: "auto",
            marginLeft: "auto",
            width: "100%"
        }
    },
    contentAboutMeDetailItem: {
        display: "flex",
        width: "100%",
        marginLeft: "auto",
        [theme.breakpoints.down(900)]: {
            marginLeft: "calc((100% - 300px) / 2) !important",
            width: "300px"
        }
    },
    avatar: {
        margin: "auto",
        width: "200px",
        height: "200px",
        borderRadius: "50%",
        borderWidth: "2px",
        borderStyle: "solid",
        borderColor: "#ffffff",
        backgroundImage: "url(\"static/images/deBuhr_tim.png\")",
        backgroundPosition: "50% 50%",
        backgroundSize: "contain"
    },
    contentContact: {
        width: "100%",
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down(900)]: {
            flexDirection: "column"
        }
    },
    contactDataArea: {
        width: "50%",
        padding: "60px 30px",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down(900)]: {
            width: "calc(100% - 60px)"
        }
    },
    contactFormArea: {
        width: "50%",
        padding: "0 60px",
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down(900)]: {
            padding: "0 30px",
            width: "calc(100% - 30px - 30px)"
        }
    },
    contactForm: {
        display: "flex",
        flexDirection: "column",
        margin: "60px 0 60px auto",
        padding: "60px 30px",
        backgroundColor: "#e7e7e7",
        width: "300px",
        [theme.breakpoints.down(900)]: {
            marginTop: "0",
            width: "calc(100% - 60px)"
        }
    },
    materialIcon: {
        fontSize: "18px !important",
        margin: "auto 10px auto 0",
        color: "#000000"
    },
    icon: {
        width: "18px",
        height: "18px",
        margin: "auto 10px auto 0",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "50% 50%",
        backgroundSize: "contain"
    },
    link: {
        textDecoration: "none",
        color: "rgb(105, 105, 105)",
        "&:hover": {
            textDecoration: "underline",
        }
    }
}));

export default function Home(props: PageProps) {

    const classes = useStyles();

    const iconStyle = (): CSSProperties => {
        return {
            fontSize: "80px",
            fontWeight: 700
        }
    }

    useEffect(() => {
        props.onChangeContentTitle(TITLES.HOME_TITLE)
    }, [props]);

    return (
        <>
            <div className={classes.contentWithBackground}
                 style={{borderTopLeftRadius: "10px", borderTopRightRadius: "10px",}}>
                <div className={classes.contentTeaser}>
                    <Typography variant="h3"
                                component="div"
                                className={classes.contentHeader}>
                        Software Consultant & Full Stack Developer
                    </Typography>
                    <Typography variant="body1"
                                component="div"
                                className={classes.contentBody}>
                        Seit 2008 sammle ich Erfahrungen in der Java Enterprise Berufswelt. Dabei unterstützte ich
                        bereits viele Kundenprojekte, durch praktische Mitarbeit sowie Schulungen und Beratung.
                        Meine Erfahrungen konnte ich dabei in den unterschiedlichsten Branchen sammeln.
                    </Typography>
                    <Typography variant="body1"
                                component="div"
                                className={classes.contentBody}>
                        Gerne unterstütze ich auch <strong>Ihr Projekt</strong>!
                    </Typography>
                </div>
                <div className={classes.contentDetails}>
                    <div className={classes.contentDetail}>
                        <Icon className={classes.contentDetailIcon} style={iconStyle()}>code</Icon>
                        <Typography variant="h4"
                                    component="div">
                            Entwicklung
                        </Typography>
                        <Typography variant="body1"
                                    component="div"
                                    className={classes.contentBody}>
                            Gerne unterstütze ich Sie und Ihr Entwicklungsteam in Ihren Softwareprojekten. Ob
                            Neuentwicklung, Migration oder Transformation Ihrer Altsysteme auf neuere Technologien
                            oder in Microservice-Architekturen.
                        </Typography>
                    </div>
                    <div className={classes.contentDetail}>
                        <Icon className={classes.contentDetailIcon} style={iconStyle()}>groups</Icon>
                        <Typography variant="h4"
                                    component="div">
                            Beratung
                        </Typography>
                        <Typography variant="body1"
                                    component="div"
                                    className={classes.contentBody}>
                            Lassen Sie sich beraten bei der Einführung von Microserviceentwicklung und dem damit
                            verbunden Mindshift im Entwicklungsvorgehen. Profitieren Sie von meiner Erfahrung durch
                            Code- oder Architekturreviews. Ich berate Sie gerne bei der Auswahl der passenden
                            Technologien oder Tools für Ihre Softwareprojekte.
                        </Typography>
                    </div>
                    <div className={classes.contentDetail}>
                        <Icon className={classes.contentDetailIcon} style={iconStyle()}>school</Icon>
                        <Typography variant="h4"
                                    component="div">
                            Schulung
                        </Typography>
                        <Typography variant="body1"
                                    component="div"
                                    className={classes.contentBody}>
                            Sie oder Ihr Entwicklungsteam möchte sich im Bereich Java Enterprise oder Microservices
                            weiterbilden? Ich leite gerne Schulungen oder Workshops bei Ihnen vor Ort. Diese werden
                            individuell mit Ihnen abgestimmt, damit Sie den größtmöglichen Nutzen daraus ziehen können.
                        </Typography>
                    </div>
                </div>
            </div>
            <div className={classes.contentAboutMe}>
                <div className={classes.contentAboutMeContact}>
                    <Typography variant="h4"
                                component="div">
                        Tim de Buhr
                    </Typography>
                    <Typography variant="body1"
                                component="div">
                        Software Consultant & Full Stack Developer
                    </Typography>
                </div>
                <div className={classes.contentAboutMeAvatar}>
                    <div className={classes.avatar}/>
                </div>
                <div className={classes.contentAboutMeDetails}>
                    <Typography className={classes.contentAboutMeDetailItem}
                                variant="body1"
                                component="div">
                        <ul>
                            <li>Baujahr: 1981</li>
                            <li>Verheiratet, 1 Kind</li>
                            <li>Interessen: Segeln, Musik, Laufen</li></ul>
                    </Typography>
                </div>

            </div>
            <div className={classes.contentWithBackground}>
                <div className={classes.contentContact}>
                    <div className={classes.contactDataArea}>
                        <Typography style={{marginBottom: "30px"}}
                                    variant="h4"
                                    component="div">
                            Kontaktieren Sie mich
                        </Typography>
                        <Typography style={{marginBottom: "30px", fontSize: "18px"}}
                                    variant="body1"
                                    component="div">
                            Tim de Buhr
                        </Typography>
                        <Typography style={{display: "flex"}}
                                    variant="body1"
                                    component="div">
                            <Icon className={classes.materialIcon}>phone</Icon>
                            <a className={classes.link}
                               href="tel:004944885204130">+49 4488 5204130</a>
                        </Typography>
                        <Typography style={{display: "flex"}}
                                    variant="body1"
                                    component="div">
                            <Icon className={classes.materialIcon}>mail</Icon>
                            <a className={classes.link}
                               href="mailto:consulting@tdbit.de">consulting@tdbit.de</a>
                        </Typography>
                        <Typography style={{display: "flex"}}
                                    variant="body1"
                                    component="div">
                            <div className={classes.icon}
                                 style={{backgroundImage: "url(\"static/images/twitter.png\")"}}/>
                            <a className={classes.link}
                               href="https://twitter.com/_tooltime"
                               target="_blank"
                               rel="noopener noreferrer">_tooltime</a>
                        </Typography>
                        <Typography style={{display: "flex"}}
                                    variant="body1"
                                    component="div">
                            <div className={classes.icon}
                                 style={{backgroundImage: "url(\"static/images/xing-logo.png\")"}}/>
                            <a className={classes.link}
                               href="https://www.xing.com/profile/Tim_deBuhr"
                               target="_blank"
                               rel="noopener noreferrer">XING Profil</a>
                        </Typography>
                        <Typography style={{display: "flex"}}
                                    variant="body1"
                                    component="div">
                            <Icon className={classes.materialIcon}>contact_mail</Icon>
                            <a className={classes.link}
                               href="https://www.freelance.de/Freiberufler/210059-Software-Consultant-Full-Stack-Developer"
                               target="_blank"
                               rel="noopener noreferrer">freelance.de</a>
                        </Typography>
                    </div>
                    <div className={classes.contactFormArea}>
                        <div className={classes.contactForm}>
                            In kürze möglich!
                            <Typography style={{marginBottom: "30px"}}
                                        variant="h4"
                                        component="div">
                                Kontakt
                            </Typography>
                            <TextField style={{backgroundColor: "#fff", borderRadius: "4px", marginBottom: "30px"}}
                                       variant="outlined"
                                       disabled
                                       required
                                       label="Name"
                                       placeholder="Ihr Name"/>
                            <TextField style={{backgroundColor: "#fff", borderRadius: "4px", marginBottom: "30px"}}
                                       variant="outlined"
                                       disabled
                                       required
                                       label="E-Mail"
                                       placeholder="Ihre E-Mail-Adresse"/>
                            <TextField style={{backgroundColor: "#fff", borderRadius: "4px", marginBottom: "30px"}}
                                       variant="outlined"
                                       disabled
                                       required
                                       multiline
                                       label="Ihre Nachricht"
                                       placeholder="Die Nachricht, die Sie mir zukommen lassen wollen"/>
                            <Typography variant="body1"
                                        component="div">
                                <strong>Datenschutzhinweis</strong>
                            </Typography>
                            <div style={{display: "flex", flexDirection: "row"}}>
                                <Checkbox disabled
                                          style={{marginBottom: "auto"}}
                                          color="primary"/>
                                <div style={{margin: "12px auto 30px 0"}}>Ich bin damit einverstanden, dass meine übermittelten Daten auf dieser Website gespeichert werden, damit Sie auf meine Anfrage reagieren können.
                                </div>
                            </div>
                            <Button disabled
                                    variant="contained"
                                    style={{backgroundColor: "rgb(63,112,179)", color: "#ffffff"}}>Abschicken</Button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
