import React, {useEffect} from "react";
import {Typography} from "@mui/material";
import {makeStyles} from "@mui/styles";
import * as TITLES from "../constants/titles";
import {PageProps} from "./Content";

const useStyles = makeStyles(() => {
    return {
        typographyH1: {
            textAlign: "center"
        },
        contentBlock: {
            marginBottom: "20px"
        }
    };
});

export default function Lectures(props: PageProps) {
    const classes = useStyles();

    useEffect(() => {
        props.onChangeContentTitle(TITLES.LECTURES_TITLE);
    }, [props]);

    return (
        <div style={{
            height: "calc(100%- 300px- 90px",
            backgroundColor: "#ffffff",
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            padding: "40px"}}>
            <div style={{margin: "auto", maxWidth: "900px"}}>
                <Typography variant="h6" component="div">
                    <strong>Verteiltes Logging und Tracing über Microservices und Web-Apps</strong>
                </Typography>
                <Typography variant="body1" component="div" className={classes.contentBlock}>
                    <ul>
                        <li>WJAX - November 2024</li>
                        <li>Java Forum Nord - September 2024</li>
                        <li>JAX - April 2024</li>
                        <li>WJAX - November 2023</li>
                    </ul>
                    Teams, die Microservices und Webanwendungen entwickeln, stehen vor der Herausforderung,
                    Logging und Tracing aus verschiedenen Welten in den Griff zu bekommen. Während dieses
                    Vortrags wird gezeigt, wie Logging und Tracing in bestehende Microservices
                    (z. B. Quarkus oder Spring Boot) und Webanwendungen (z. B. React oder Angular) auf Basis
                    von OpenTelemetry integriert und visualisiert werden können.
                </Typography>
                <Typography variant="h6" component="div">
                    <strong>Playing like a Nerd</strong>
                </Typography>
                <Typography variant="body1" component="div" className={classes.contentBlock}>
                    <ul>
                        <li><a rel="nofollow noreferrer"
                               href="https://www.herbstcampus.de/hc11/program/sessions.html#40">
                            Herbstcampus - September 2011</a></li>
                    </ul>
                    Der Lego-Mindstorm-Roboter, eine iPad/iPhone basierte Fernsteuerung und das ganze auch noch
                    mit Java.<br/><br/>
                    Diese „Playing like a Nerd“-Session zeigt auf nicht ganz ernst gemeinte Art und Weise,
                    dass Programmieren durchaus Spaß machen kann. Seien sie gespannt, wie sich mit Hilfe
                    von Java – und einer kleinen Portion Objective-C – diese scheinbar so verschiedenen
                    Welten miteinander verbinden lassen.
                </Typography>
                <Typography variant="h6" component="div">
                    <strong>3D - Domain Driven Design on top</strong>
                </Typography>
                <Typography variant="body1" component="div" className={classes.contentBlock}>
                    <ul>
                        <li><a rel="nofollow noreferrer"
                               href="https://www.herbstcampus.de/hc11/program/sessions.html#39">
                            Herbstcampus - September 2011</a></li>
                    </ul>
                    Die Modellierung von Fachlichkeit im Einklang mit Kundenanforderungen gehört zu den
                    am stärksten unterschätzten Herausforderungen der Software-Entwicklung. Agile Methoden
                    werden heute gerne genutzt um dieses Problem zu lösen.<br/><br/>
                    Im Rahmen dieses Vortrags wird zunächst vorgestellt, wie Domain Driven Design
                    Anforderungsdefinition und -umsetzung von Software-Projekten verändert und wie sich
                    diese Veränderung auf die Anwendung verschiedener agiler Methoden auswirkt. Aufbauend
                    darauf wird gezeigt, in welche agilen Entwicklungsprozesse Domain Driven Design erfolgreich
                    eingebunden werden kann, damit die Vorzüge beider Vorgehensmodelle gemeinsam zur Geltung
                    kommen und so zum Gelingen des Gesamtprojektes beitragen können.
                </Typography>
                <Typography variant="h6" component="div">
                    <strong>Konkurrenten oder Partner? - Domain Driven Design trifft agile Entwicklung</strong>
                </Typography>
                <Typography variant="body1" component="div" className={classes.contentBlock}>
                    <ul>
                        <li><a rel="nofollow noreferrer"
                               href="https://www.herbstcampus.de/hc10/program/sessions.html#45">
                            Herbstcampus - September 2010</a></li>
                    </ul>
                    Die Modellierung von Fachlichkeit im Einklang mit Kundenanforderungen gehört zu den am stärksten
                    unterschätzten Herausforderungen der Software-Entwicklung. Domain Driven Design und agile Methoden
                    gehen dieses Problem aus verschiedenen Richtungen an.
                    <br/><br/>
                    Der Vortrag stellt anhand von praxisbezogenen Beispielen vor, wie beide Vorgehensweisen erfolgreich
                    zusammenspielen können und wo es Reibungspunkte gibt. Im Rahmen der Session wird zunächst
                    vorgestellt, wie Domain Driven Design Anforderungsdefinition und -umsetzung von Software-Projekten
                    verändert und wie sich diese Veränderung auf die Anwendung verschiedener agiler Methoden auswirkt.
                    Aufbauend darauf wird gezeigt, in welche agilen Entwicklungsprozesse Domain Driven Design
                    erfolgreich eingebunden werden kann, damit die Vorzüge beider Vorgehensmodelle gemeinsam zur Geltung
                    kommen und so zum Gelingen des Gesamtprojektes beitragen können.
                </Typography>
            </div>
        </div>
    );
}
