import {createTheme} from "@mui/material/styles";

const TdbTheme = createTheme({
  components: {
    MuiTypography: {
      styleOverrides: {
        body1: {
          fontFamily: "Rubik, sans-serif",
          fontSize: "16px",
          fontWeight: 400,
          lineHeight: "24px",
          margin: "0 0 10px"
        },
        body2: {
          fontFamily: "Rubik, sans-serif",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "16px"
        },
        h1: {
          fontFamily: "Rubik, sans-serif",
          fontSize: "34px",
          fontWeight: 500,
          lineHeight: "40px"
        },
        h2: {
          fontFamily: "Rubik, sans-serif",
          fontSize: "20px",
          fontWeight: 500,
          lineHeight: "28px",
          textTransform: "uppercase",
          margin: "0 0 20px"
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "&$disabled": {
            color: "#000000"
          }
        },
        underline: {
          "&$disabled:before": {
            borderBottom: "1px lightgrey",
            borderBottomStyle: "solid"
          },
          "&:before": {
            borderBottom: "1px solid lightgrey"
          },
          "&:before:": {
            borderBottom: "1px solid lightgrey"
          },
          "&:hover:not($disabled):before": {
            borderBottom: "1px solid darkgrey"
          }
        },
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {},

      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#ffffff",
          textAlign: "left",
        }
      }
    }
  },
  palette: {
    primary: {
      main: "#4c627f",
    },
    text: {
      primary: "#000000",
    }
  }
});

export default TdbTheme;
