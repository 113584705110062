import * as ROUTES from "../constants/routes";
import Lectures from "./Lectures";
import LegalNotice from "./LegalNotice";
import DataProtection from "./DataProtection";
import Home from "./Home";
import NotFound from "./NotFound";
import React, {PropsWithChildren} from "react";
import "./Content.css";
import {makeStyles} from "@mui/styles";
import Development from "./Development";
import {Route, Routes} from "react-router-dom";
import {Theme} from "@mui/material";

const useStyles = makeStyles((theme: Theme) => ({
    content: {
        display: "flex",
        flexDirection: "column",
        color: "rgb(105, 105, 105)",
        width: "100%",
        minHeight: "100%",
        maxWidth: "1280px",
        margin: "90px auto 0 auto"
    },
    contentTitleContainer: {
        height: "300px",
        display: "flex",
        flexDirection: "column"
    },
    contentTitle: {
        fontSize: "40px",
        fontWeight: 700,
        margin: "auto 30px",
        color: "#fff",
        textAlign: "center",
        wordWrap: "break-word"
    },
    contentContainer: {
        height: "100%"
    }
}));

export interface PageProps extends PropsWithChildren<any> {
    onChangeContentTitle(contentTitle: string): void;
}

interface ContentProps extends PropsWithChildren<any> {
    contentTitle: string;

    onChangeContentTitle(contentTitle: string): void;
}

export default function Content(props: ContentProps) {

    const {contentTitle, onChangeContentTitle} = props;

    const classes = useStyles();

    return (
        <div id="content" className={classes.content}>
            <div className={classes.contentTitleContainer}>
                <div className={classes.contentTitle}>{contentTitle}</div>
            </div>
            <div className={classes.contentContainer}>
                <Routes>
                    <Route path={ROUTES.LECTURES} element={
                        <Lectures onChangeContentTitle={onChangeContentTitle}/>
                    }/>
                    <Route path={ROUTES.DEVELOPMENT} element={
                           <Development onChangeContentTitle={onChangeContentTitle}/>
                    }/>
                    <Route path={ROUTES.LEGAL_NOTICE} element={
                           <LegalNotice onChangeContentTitle={onChangeContentTitle}/>
                    }/>
                    <Route path={ROUTES.DATA_PROTECTION} element={
                        <DataProtection onChangeContentTitle={onChangeContentTitle}/>
                    }/>
                    <Route path="/" element={
                        <Home onChangeContentTitle={onChangeContentTitle}/>
                    }/>
                    <Route element={<NotFound/>}/>
                </Routes>
            </div>
        </div>
    );
}
